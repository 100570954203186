import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// firebase imports
import { doc, getDoc } from "firebase/firestore"
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../Firebase"
// front end styling
import logo from '../assets/logo-no-circle.png'

export const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [alertCreds, setAlertCreds] = useState(false);
    const [signInAttempts, setSignInAttempts] = useState(0);
    const [alertWait, setAlertWait] = useState(false);

    // get user credentials and redirect to user page
    const credUser = async (event) => {
        try {
            event.preventDefault();
            const userCreds = await signInWithEmailAndPassword(auth, email, password);
            const usersRef = doc(db, "users", userCreds.user.uid);
            const snap = await getDoc(usersRef);
            navigate("/" + snap.data().displayname);
        } catch (error) {
            setAlertCreds(true);
            setSignInAttempts(signInAttempts+1);
            if (signInAttempts == 6) {
                setAlertWait(true);
            }
        }
    }
    return (
        <div className="h-screen flex flex-col items-center justify-center space-y-4 font-outfit bg-gradient-to-br from-white via-white to-hyprcrd-secondary">
            <img src={logo} className="w-56" />
            <h1 className="text-center text-hyprcrd-secondary text-7xl font-bold">hypr<span className="text-hyprcrd-black font-semibold">crd</span></h1>
            <h2 className="text-center text-xl font-normal">a visual resume for creatives.</h2>
            <div className="w-full max-w-xs">
                <form onSubmit={credUser}>
                    <p className={alertCreds ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>email or password incorrect.</p>
                    <p className={alertWait ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>too many failed login attempts, wait 5 minutes.</p>
                    <div class="mb-4">
                        <input class="appearance-none rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline placeholder:text-white/50" id="email" type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div class="mb-4">
                        <input class="appearance-none border rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline placeholder:text-white/50" id="password" type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div class="flex flex-col items-center justify-between">
                        <input class="bg-hyprcrd-primary text-white font-bold py-2 px-16 rounded-full focus:outline-none focus:shadow-outline text-2xl" type="submit" value="login" />
                        <Link className="inline-block align-baseline font-semibold text-sm text-hyprcrd-secondary" to='/signup'>dont have an account?</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}
