import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { auth } from "../Firebase";
import { signOut } from "firebase/auth";

export const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // sign out user
        signOut(auth).then(() => {
            navigate('/')
        }).catch((error) => {
            console.log(error);
        })
    }, [])

    return (
        <div className="h-screen w-screen flex flex-col items-center justify-center">
            <h1 className="text-3xl font-semibold">logging out...</h1>
        </div>
    )
}