import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
// firebase imports
import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { db, auth } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";
// custom components
import { UserHeader } from "../components/UserHeader";
import { Menu } from "../components/Menu";

export const User = () => {
  const params = useParams(); // params passed into Route
  const [user, setUser] = useState([]); // user data
  const [isAuth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userCards, setUserCards] = useState([]);
  const [theme, setTheme] = useState({});
  const [clientUsername, setClientUsername] = useState("");
  const usersReference = collection(db, "users"); // reference for user
  const navigate = useNavigate(); // url rerouter

  // get client username for routing
  const getClient = async () => {
    // get user doc from auth user id
    const docReference = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docReference);
    const data = docSnap.data();
    setClientUsername(data.displayname);
  }

  // get user information for /:username
  const getUser = async () => {
    try {
      // query users reference for instance where displayname is equal to username param
      const userQuery = query(usersReference, where("displayname", "==", params.username));
      // when the snap shot of db is returned from query
      onSnapshot(userQuery, (snapshot) => {
        // if the snapshot is empty, need to 404 that user does not exist
        if (snapshot.empty) {
          navigate("/"); // for now: just go home
        } else {
          // get data from snapshot
          const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }));
          if (data.length == 0) {
            navigate("/"); // for now: just go home
          }
          onAuthStateChanged(auth, (user) => {
            if (user) {
              setAuth(true);
            } else {
              setAuth(false);
            }
          });
          setUserCards(data[0].cards)
          setUser(data)
          setTheme(data[0].theme)
        }

      });
      // get client username
      // get user doc from auth user id
      const docReference = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docReference);
      const data = docSnap.data();
      setClientUsername(data.displayname);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // due to the fact that auth loading is not guarenteed before render
    //  need to let auth state change handle getting userName in hook
    const unsub = auth.onAuthStateChanged((authObj) => {
      unsub();
      getUser();
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <div className="min-h-screen font-outfit p-2 flex items-center justify-center">
        <h1 className="font-bold">loading...</h1>
      </div>
    )
  }
  // if the user has no cards on profile: will have different display
  if (userCards.length === 0) {
    // if the user is auth and its their profile
    if (isAuth && auth.currentUser.uid === user[0].id) {
      return (
        <div className="min-h-screen font-outfit p-2" style={{ backgroundColor: theme.background }}>
          <Menu isAuth={isAuth} username={params.username} color={theme.button} />
          <div className="w-full flex flex-col items-center">
            <div className="sm:w-1/2 flex flex-col justify-center items-center text-center">
              <UserHeader username={params.username} data={user} theme={theme} />
              <div className="flex flex-row w-3/4 items-center justify-center space-x-2">
                <Link className="rounded-full mt-6 pl-2 pr-2 w-1/3 text-sm" to='/edit' style={{ color: theme.text, backgroundColor: theme.button }}>edit profile</Link>
                <Link className="rounded-full mt-6 pl-2 pr-2 w-1/3 text-sm" to='/style' style={{ color: theme.text, backgroundColor: theme.button }}>edit style</Link>
              </div>
              <h1 className="font-black text-3xl pt-6" style={{ color: theme.card }}>it looks like your profile is empty :/</h1>
              <Link to='/add' className="rounded-full text-white bg-black text-xl px-4 py-1 mt-10" style={{ color: theme.text, backgroundColor: theme.button }}>add first link</Link>
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="min-h-screen font-outfit p-2" style={{ backgroundColor: theme.background }}>
          <Menu isAuth={isAuth} username={params.username} color={theme.button} />
          <div className="w-full flex flex-col items-center">
            <div className="sm:w-1/2 flex flex-col justify-center items-center text-center">
              <UserHeader username={params.username} data={user} theme={theme} />
              <h1 className="font-black text-3xl pt-6" style={{ color: theme.card }}>it looks this users profile is empty :/</h1>
            </div>
          </div>
        </div>
      );
    }

  } else {
    if (isAuth && auth.currentUser.uid === user[0].id) {
      return (
        <div className="min-h-screen font-outfit p-2" style={{ backgroundColor: theme.background }}>
          <Menu isAuth={isAuth} username={params.username} color={theme.button} />
          <div className="w-full flex flex-col items-center">
            <div className="sm:w-1/2 flex flex-col justify-center items-center text-center">
              <UserHeader username={params.username} data={user} theme={theme} />
              <div className="flex flex-row w-3/4 items-center justify-center space-x-2">
                <Link className="rounded-full mt-6 pl-2 pr-2 pt-1 pb-1 w-1/3 text-sm font-thin" to='/edit' style={{ color: theme.text, backgroundColor: theme.button }}>edit profile</Link>
                <Link className="rounded-full mt-6 pl-2 pr-2 pt-1 pb-1 w-1/3 text-sm font-thin" to='/style' style={{ color: theme.text, backgroundColor: theme.button }}>edit style</Link>
              </div>
              <div className="flex flex-col space-y-4 pt-4 w-full items-center mb-2">
                {userCards.map((item, index) => (
                  <a href={item.url} target="_blank" className="w-3/4 text-center">
                    <div key={index} className="drop-shadow-md rounded-md pt-6 pb-6 text-lg font-medium" style={{ backgroundColor: theme.card, color: theme.background }}>
                      {item.title}
                    </div>
                  </a>
                ))}
                <Link className="flex items-center justify-center rounded-full w-12 h-12 font-thin text-5xl" style={{ backgroundColor: theme.button, color: theme.text }} to='/add'>+</Link>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="min-h-screen font-outfit p-2" style={{ backgroundColor: theme.background }}>
          <Menu isAuth={isAuth} username={clientUsername} color={theme.button} />
          <div className="w-full flex flex-col items-center">
            <div className="sm:w-1/2 flex flex-col justify-center items-center">
              <UserHeader username={params.username} data={user} theme={theme} />
              <div className="flex flex-col space-y-4 pt-4 w-full items-center mb-2">
                {userCards.map((item, index) => (
                  <a href={item.url} target="_blank" className="w-3/4 text-center">
                    <div key={index} className="bg-white drop-shadow-md rounded-md pt-6 pb-6 text-lg font-medium" style={{ backgroundColor: theme.card, color: theme.background }}>
                      {item.title}
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
