import React from "react";
import { Link } from "react-router-dom"
import logo from "../assets/logo-no-circle.png"

export const Landing = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center space-y-4 font-outfit bg-gradient-to-br from-white via-white to-hyprcrd-secondary">
            <img src={logo} className="w-48" />
            <h1 className="text-center text-hyprcrd-secondary text-7xl font-bold">hypr<span className="text-hyprcrd-black font-semibold">crd</span></h1>
            <h2 className="text-center text-xl font-normal">a visual resume for creatives.</h2>
            <div className="flex flex-col text-center space-y-12 pt-20 text-2xl font-semibold ">
                <Link className="bg-hyprcrd-primary rounded-full text-white pt-6 pb-6 pl-14 pr-14" to='/login'>login</Link>
                <Link className="rounded-full border-4 border-hyprcrd-secondary text-hyprcrd-secondary pt-6 pb-6 pl-14 pr-14" to='signup'>signup</Link>
            </div>
        </div>
    );
}