import { React, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
// firebase imports
import { auth, db } from '../Firebase'
import { getDocs, doc, collection, query, where, setDoc, Timestamp } from 'firebase/firestore'
import { createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification } from 'firebase/auth'
// front end styling
import logo from '../assets/logo-no-circle.png'

export const Signup = () => {
    const navigate = useNavigate();
    // user sign up information
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    // alerts for form
    const [alertFirstname, setAlertFirstName] = useState(false);
    const [alertLastname, setAlertLastName] = useState(false);
    const [alertBirthday, setAlertBirthday] = useState(false);
    const [alertUsername, setAlertUsername] = useState(false);
    const [alertUsername2, setAlertUsername2] = useState(false);
    const [alertEmail, setAlertEmail] = useState(false);
    const [alertPassword, setAlertPassword] = useState(false);
    const [alertConfirmPassword, setAlertConfirmPassword] = useState(false);
    const routes = ['login', 'signup', 'qr', 'edit', 'add', 'style', 'settings', 'logout', 'passwordreset', 'ghost', 'editlink', 'deletelink']


    const isDisplayNameInUse = async () => {
        const usersRef = collection(db, "users");
        const userQuery = query(usersRef, where("displayname", "==", username));
        const userQuerySnapshot = await getDocs(userQuery);
        if (userQuerySnapshot.empty) {
            return false;
        }
        return true;
    }

    const handleBirthdayChange = (e) => {
        setBirthday(Timestamp.fromDate(new Date(e.target.value)));
    }

    const postUser = async (event) => {
        try {
            let alert = false;
            if (firstName == '') {
                setAlertFirstName(true);
                alert = true;
            } else { setAlertFirstName(false); }
            if (lastName == '') {
                setAlertLastName(true);
                alert = true;
            } else { setAlertLastName(false); }
            const date = new Date();
            if (birthday === null) {
                setAlertBirthday(true);
                alert = true;
            } else if (date.getFullYear() - birthday.toDate().getFullYear() < 13) {
                setAlertBirthday(true);
                alert = true;
            } else { setAlertBirthday(false); }
            if (await isDisplayNameInUse() || routes.includes(username)) {
                setAlertUsername(true);
                alert = true;
            } else { setAlertUsername(false); }
            if (username.includes('/') || username.includes('\\')) {
                setAlertUsername2(true);
                alert = true;
            } else { setAlertUsername2(false); }
            if (email == '') {
                setAlertEmail(true);
                alert = true;
            } else { setAlertEmail(false); }
            if (password == '') {
                setAlertPassword(true);
                alert = true;
            } else { setAlertPassword(false); }
            if (password !== confirmPassword) {
                setAlertConfirmPassword(true);
                alert = true;
            } else { setAlertConfirmPassword(false); }
            if (!alert) {
                await createUserWithEmailAndPassword(auth, email, password)
                await sendEmailVerification(auth.currentUser);
                // when the auth state changes, post and redirect
                onAuthStateChanged(auth, (user) => {
                    const users = collection(db, "users");
                    setDoc(doc(users, user.uid), {
                        firstname: firstName,
                        lastname: lastName,
                        birthday: birthday,
                        displayname: username,
                        description: "",
                        profile_image: "gs://hyprcrd.appspot.com/profile_images/ghost_icon.png",
                        cards: [],
                        theme: {
                            background: "#FFFFFF",
                            card: "#000000",
                            button: "#7F8487",
                            secondary: "#ffffff",
                            text: "#000000"
                        }
                    });
                    // redirect to user profile
                    navigate("/" + username);
                });
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className="p-8 min-h-screen flex flex-col items-center justify-center space-y-4 font-outfit bg-gradient-to-br from-white via-white to-hyprcrd-secondary">
            <img src={logo} className="w-48" />
            <h1 className="text-center text-hyprcrd-secondary text-7xl font-bold">hypr<span className="text-hyprcrd-black font-semibold">crd</span></h1>
            <h2 className="text-center text-xl font-normal">a visual resume for creatives.</h2>
            <div className="w-full max-w-xs">
                <div class="mb-4">
                    <p className={alertFirstname ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>please enter first name</p>
                    <input class="appearance-none rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline placeholder:text-white/50" id="firstName" type="text" placeholder="first name" onChange={(e) => setFirstName(e.target.value)} required />
                </div>
                <div class="mb-4">
                    <p className={alertLastname ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>please enter last name</p>
                    <input class="appearance-none border rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline placeholder:text-white/50" id="lastName" type="text" placeholder="last name" onChange={(e) => setLastName(e.target.value)} required />
                </div>
                <div class="mb-4">
                    <p className={alertBirthday ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>please enter valid birthday (13 or older)</p>
                    <input class="rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white/50 leading-tight focus:outline-none focus:shadow-outline" type="date" onChange={handleBirthdayChange} id="birthday" required />
                </div>
                <div class="mb-4">
                    <p className={alertUsername ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>username already taken</p>
                    <p className={alertUsername2 ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>username cannot contain '/'</p>
                    <input class="appearance-none border rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline placeholder:text-white/50" id="username" type="text" placeholder="username" onChange={(e) => setUsername(e.target.value.replace(/\s/g, '').toLowerCase())} maxLength={30} required />
                </div>
                <div class="mb-4">
                    <p className={alertEmail ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>please enter valid email</p>
                    <input class="appearance-none border rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline placeholder:text-white/50" id="email" type="text" placeholder="email" onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div class="mb-4">
                    <p className={alertPassword ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>please enter valid password</p>
                    <input class="appearance-none border rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline placeholder:text-white/50" id="password" type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <div class="mb-6">
                    <p className={alertConfirmPassword ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>please confirm password</p>
                    <input class="appearance-none border rounded-lg bg-hyprcrd-secondary w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline placeholder:text-white/50" id="confirmPassword" type="password" placeholder="confirm password" onChange={(e) => setConfirmPassword(e.target.value)} required />
                </div>
                <div class="flex flex-col items-center justify-between">
                    <button class="bg-hyprcrd-primary text-white font-bold py-2 px-16 rounded-full focus:outline-none focus:shadow-outline text-2xl" onClick={postUser}>create account</button>
                    <Link className="inline-block align-baseline font-semibold text-sm text-hyprcrd-secondary" to='/login'>already have an account?</Link>
                </div>
            </div>
        </div>
    );
}