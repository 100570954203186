import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Menu = (props) => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const { isAuth, username, color } = props
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // if menu is open and the clicked target is not within menu
            if (navbarOpen && ref.current && !ref.current.contains(e.target)) {
                setNavbarOpen(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // clean up event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    }, [navbarOpen]);

    if (isAuth) {
        return (
            <div className="m-2" style={{ color: color }}>
                <div className="w-full flex flex-row justify-between">
                    <Link to='/' className="text-sm font-bold mr-5 py-2">
                        <h1 className='font-black text-2xl'>hyprcrd</h1>
                    </Link>
                    <button
                        className="text-black cursor-pointer text-xl leading-none px-3 py-1 rounded block outline-none focus:outline-none"
                        type="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}>
                        {navbarOpen ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8"
                                viewBox="0 0 20 20"
                                fill={color}
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke={color}
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        )}
                    </button>
                </div>
                <div
                    className={
                        "flex-grow items-center float-right absolute right-0 ml-5 mr-5" +
                        (navbarOpen ? " flex" : " hidden")
                    }
                >
                    <ul className="flex flex-col list-none lg:ml-auto bg-white rounded-xl shadow-lg pl-10 pr-10">
                        <li className="nav-item">
                            <a href={`/${username}`} className="px-3 py-2 flex items-center text-lg font-normal leading-snug text-black hover:opacity-75">profile</a>
                        </li>
                        <li className="nav-item">
                            <Link to='/qr' className="px-3 py-2 flex items-center text-lg font-normal leading-snug text-black hover:opacity-75">qr code</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/settings' className="px-3 py-2 flex items-center text-lg font-normal leading-snug text-black hover:opacity-75">settings</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/logout' className="px-3 py-2 flex items-center text-lg font-normal leading-snug text-black hover:opacity-75">log out</Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    } else {
        return (
            <div className="m-2" style={{ color: color }}>
                <div className="w-full flex flex-row justify-between">
                    <Link to='/' className="text-sm font-bold leading-relaxed inline-block mr-5 py-2 whitespace-nowrap">
                        <h1 className='font-black text-2xl sm:text-5xl'>hyprcrd</h1>
                    </Link>

                    <button
                        className="text-black cursor-pointer text-xl leading-none px-3 py-1 rounded block outline-none focus:outline-none"
                        type="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}>
                        {navbarOpen ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8"
                                viewBox="0 0 20 20"
                                fill={color}
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke={color}
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        )}
                    </button>
                </div>
                <div
                    className={
                        "flex-grow items-center float-right absolute right-0 ml-5 mr-5" +
                        (navbarOpen ? " flex" : " hidden")
                    }
                >
                    <ul className="flex flex-col list-none lg:ml-auto bg-white rounded-xl shadow-lg pl-10 pr-10">
                        <li className="nav-item">
                            <Link to='/signup' className="px-3 py-2 flex items-center text-lg font-normal leading-snug text-black hover:opacity-75">create account</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/login' className="px-3 py-2 flex items-center text-lg font-normal leading-snug text-black hover:opacity-75">login</Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }


}