import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
// firebase
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db, storage, auth } from "../Firebase";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// custom components
import { Menu } from "../components/Menu";

export const Edit = () => {
    const [isAuth, setAuth] = useState(false);
    const [user, setUser] = useState({});
    const [imageUpload, setImageUpload] = useState(null);
    const [currentImage, setCurrentImage] = useState("");
    const [userBio, setUserBio] = useState("");
    const [username, setUsername] = useState("");
    const [cards, setCards] = useState([]);
    const [alertUsername, setAlertUsername] = useState(false);
    const [alertUsername2, setAlertUsername2] = useState(false);
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();
    const routes = ['login', 'signup', 'qr', 'edit', 'add', 'style', 'settings', 'logout', 'passwordreset', 'ghost', 'editlink', 'deletelink']

    const getUser = async () => {
        // get user doc from auth user id
        const docReference = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docReference);
        const data = docSnap.data();
        setUser(data);
        setUserBio(data.description);
        setUsername(data.displayname);
        setCards(data.cards);
        // get current image from user
        const url = await getDownloadURL(ref(storage, `${data.profile_image}`))
        setCurrentImage(url);
    }

    // if the username entered is in use
    const isDisplayNameInUse = async () => {

        const usersRef = collection(db, "users");
        const userQuery = query(usersRef, where("displayname", "==", username));

        const userQuerySnapshot = await getDocs(userQuery);
        if (userQuerySnapshot.empty) {
            return false;
        }
        if (user.displayname == username) {
            return false;
        }
        return true;
    }

    const uploadImage = async () => {
        if (imageUpload == null) return; // if no image was uploaded
        const imageRef = ref(storage, `profile_images/${auth.currentUser.uid}`);
        await uploadBytes(imageRef, imageUpload).then(() => {
            // do nothing
        }).catch((error) => {
            console.log(error);
        })
        // update doc with new user image
        const url = await getDownloadURL(ref(storage, `profile_images/${auth.currentUser.uid}`)).then((url) => {
            const userRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(userRef, { profile_image: url }).then(docRef => {
                console.log("image uploaded successfully to doc");
            }).catch(error => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        });


    }

    const handleClick = () => {
        hiddenFileInput.current.click();
    }

    const handleSubmit = async () => {
        let submit = true;
        // do not let user edit username to a username that is already in us
        if (await isDisplayNameInUse() || routes.includes(username)) {
            setAlertUsername(true);
            submit = false;
        } else { setAlertUsername(false); }
        if (username.includes('/') || username.includes('\\')) {
            setAlertUsername2(true);
            submit = false;
        } else { setAlertUsername2(false); }
        if (submit) {
            uploadImage().then(() => {
                // do nothing
            }).catch((error) => {
                console.log(error);
            });
            // upload user profile edits
            const userRef = doc(db, "users", auth.currentUser.uid);
            await updateDoc(userRef, { description: userBio, displayname: username }).then(docRef => {
                console.log("profile updated successfully to doc");
            }).catch(error => {
                console.log(error);
            });
            navigate("/" + username);
        }
    }

    useEffect(() => {
        // due to the fact that auth loading is not guarenteed before render
        //  need to let auth state change handle getting userName in hook
        const unsub = auth.onAuthStateChanged((authObj) => {
            unsub();
            if (authObj) {
                getUser();
                setAuth(true);
            } else {
                // not logged in, reroute to home page
                navigate("/");
            }
        })
    }, [])

    return (
        <div className="min-h-screen p-2 bg-gray-100">
            <Menu isAuth={isAuth} username={user.displayname} color={"black"} />
            <div className="space-y-2 flex flex-col items-center justify-center text-center">
                <h1 className="text-5xl font-bold">edit profile</h1>
                <img src={currentImage} className="w-48 h-48 rounded-full object-cover shadow-sm" />
                <input
                    type="file"
                    ref={hiddenFileInput}
                    className="hidden"
                    id="pfp"
                    onChange={(event) => {
                        // if the file is not undefined (i.e the user hits escape on file upload)
                        if (event.target.files[0] !== undefined) {
                            setImageUpload(event.target.files[0]);
                            setCurrentImage(URL.createObjectURL(event.target.files[0]));
                        }
                    }} />
                <button className="rounded-full border-black border pl-2 pr-2 text-sm" onClick={handleClick}>upload picture</button>
                <h1 className="font-semibold text-2xl">username</h1>
                <p className={alertUsername ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>username is already taken</p>
                <p className={alertUsername2 ? "text-red-500 text-sm font-semibold pl-2" : "hidden"}>username cannot contain '/'</p>
                <input
                    type="text"
                    onChange={(event) => {
                        setUsername(event.target.value.replace(/\s/g, '').toLowerCase());
                    }}
                    className="w-3/4 text-lg font-medium text-center border-black/50 border-2 rounded-xl"
                    maxLength={30}
                    value={username}
                />
                <h1 className="font-semibold text-2xl">bio</h1>
                <textarea
                    type="text"
                    onChange={(event) => { setUserBio(event.target.value) }}
                    className="w-3/4 rounded-xl border-black/50 border-2 p-2 pb-7 text-lg font-medium overflow-hidden"
                    maxLength={75}
                    value={userBio}
                />
                <div className="flex flex-col space-y-4 w-full items-center pb-4">
                    <h1 className={cards.length == 0 ? "hidden" : "font-semibold text-2xl"}>links</h1>
                    {cards.map((item, index) => (
                        <div className="w-3/4">
                            <a href={item.url} target="_blank" className="text-center">
                                <div key={index} className="bg-white drop-shadow-md font-medium text-lg rounded-md pb-6">
                                    <div className="flex flex-row justify-end space-x-2 text-center pt-2 mr-2">
                                        <Link className="text-sm text-black/75 font-light rounded-full border border-black/50 pl-2 pr-2 w-1/5" to={`/editlink/${index}`}>edit</Link>
                                        <Link className="text-sm text-black/75 font-light rounded-full border border-black/50 pl-2 pr-2 w-1/5" to={`/deletelink/${index}`}>delete</Link>
                                    </div>
                                    {item.title}
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                <button className='bg-black mt-2 text-white rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4' onClick={handleSubmit}>done</button>
                <Link className='border border-black mt-2 text-black rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4' to={`/${user.displayname}`}>cancel</Link>
            </div>
        </div>
    );
}