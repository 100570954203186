import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";
// firebase
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db, storage, auth } from "../Firebase";
// custom components
import { Menu } from "../components/Menu";

export const Style = () => {
    const [isAuth, setAuth] = useState(false);
    const [user, setUser] = useState({})
    const navigate = useNavigate();
    const [theme, setTheme] = useState('light');

    const style = "flex flex-col w-28 h-28 rounded-2xl drop-shadow-2xl p-1"; 
    const activeStyle = "border-2 border-black ";

    const themes = {
        light: {
            background: "#FFFFFF",
            card: "#000000",
            button: "#7F8487",
            secondary: "#ffffff",
            text: "#000000"
        },
        cyberPunk: {
            background: "#1A1B25",
            card: "#AA6373",
            button: "#822E81",
            text: "#ffffff",
            secondary: "#392061", 
        },
        lunar: {
            background: "#4e4c67",
            card: "#dcd6f7",
            button: "#A6B1E1",
            secondary: "#B4869F",
            text: "#FFFFFF"
        },
        rose: {
            background: "#F5D3C8",
            card: "#734B5E",
            button: "#565857",
            secondary: "#BCBDC0",
            text: "#FFFFFF"
        },
        lavender: {
            background: "#d5cfe1",
            card: "#464655",
            button: "#94958B",
            secondary: "#EDDFEF",
            text: "#FFFFFF"
        },
        warm: {
            background: "#EFD0CA",
            card: "#5C7457",
            button: "#979B8D",
            secondary: "#C1BCAC",
        }
    }

    const getUser = async () => {
        // get user doc from auth user id
        const docReference = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docReference);
        const data = docSnap.data();
        setUser(data);
    }

    const handleSubmit = async () => {
        const userRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userRef, {theme: themes[theme]}).then(docRef => {
            console.log("profile updated successfully to doc");
        }).catch(error => {
            console.log(error);
        });
        navigate("/" + user.displayname);
    }

    useEffect(() => {
        // due to the fact that auth loading is not guarenteed before render
        //  need to let auth state change handle getting userName in hook
        const unsub = auth.onAuthStateChanged((authObj) => {
            unsub();
            if (authObj) {
                getUser();
                setAuth(true);
            } else {
                // not logged in, reroute to home page
                navigate("/");
            }
        })
    }, [])

    return (
        <div className='h-screen space-y-4 flex flex-col items-center justify-center text-center bg-gray-100'>
            <h1 className="font-bold text-5xl text-black">select a theme</h1>
            <div className="space-y-4">
                <RadioGroup value={theme} onChange={setTheme} className="grid grid-cols-2 justify-stretch justify-items-center text-center gap-x-4">
                    <RadioGroup.Option value="light">
                        {({ checked }) => (
                            <div>
                                <h1 className="font-semibold text-2xl">light</h1>
                                <div className={checked ? activeStyle+style : style}>
                                    <div className={`w-full h-1/4 rounded-tl-xl rounded-tr-xl`} style={{backgroundColor: themes.light.background}}>

                                    </div>
                                    <div className={`w-full h-1/4`} style={{backgroundColor: themes.light.card}}>

                                    </div>
                                    <div className={`w-full h-1/4`} style={{backgroundColor: themes.light.button}}>

                                    </div>
                                    <div className={`w-full h-1/4 rounded-bl-xl rounded-br-xl`} style={{backgroundColor: themes.light.secondary}}>

                                    </div>
                                </div>
                            </div>
                        )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="cyberPunk">
                        {({ checked }) => (
                            <div>
                                <h1 className="font-semibold text-2xl">cyber punk</h1>
                                <div className={checked ? activeStyle+style : style}>
                                    <div className="w-full h-1/4 rounded-tl-xl rounded-tr-xl" style={{backgroundColor: themes.cyberPunk.background}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.cyberPunk.card}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.cyberPunk.button}}>

                                    </div>
                                    <div className="w-full h-1/4 rounded-bl-xl rounded-br-xl" style={{backgroundColor: themes.cyberPunk.secondary}}>

                                    </div>
                                </div>
                            </div>
                        )}
                    </RadioGroup.Option>
                </RadioGroup>
                <RadioGroup value={theme} onChange={setTheme} className="grid grid-cols-2 justify-stretch justify-items-center text-center gap-x-4">
                <RadioGroup.Option value="lunar">
                        {({ checked }) => (
                            <div>
                                <h1 className="font-semibold text-2xl">lunar</h1>
                                <div className={checked ? activeStyle+style : style}>
                                    <div className="w-full h-1/4 rounded-tl-xl rounded-tr-xl" style={{backgroundColor: themes.lunar.background}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.lunar.card}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.lunar.button}}>

                                    </div>
                                    <div className="w-full h-1/4 rounded-bl-xl rounded-br-xl" style={{backgroundColor: themes.lunar.secondary}}>

                                    </div>
                                </div>
                            </div>
                        )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="rose">
                        {({ checked }) => (
                            <div>
                                <h1 className="font-semibold text-2xl">rose</h1>
                                <div className={checked ? activeStyle+style : style}>
                                    <div className="w-full h-1/4 rounded-tl-xl rounded-tr-xl" style={{backgroundColor: themes.rose.background}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.rose.card}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.rose.button}}>

                                    </div>
                                    <div className="w-full h-1/4 rounded-bl-xl rounded-br-xl" style={{backgroundColor: themes.rose.secondary}}>

                                    </div>
                                </div>
                            </div>
                        )}
                    </RadioGroup.Option>
                </RadioGroup>
                <RadioGroup value={theme} onChange={setTheme} className="grid grid-cols-2 justify-stretch justify-items-center text-center gap-x-4">
                <RadioGroup.Option value="lavender">
                        {({ checked }) => (
                            <div>
                                <h1 className="font-semibold text-2xl">lavender</h1>
                                <div className={checked ? activeStyle+style : style}>
                                    <div className="w-full h-1/4 rounded-tl-xl rounded-tr-xl" style={{backgroundColor: themes.lavender.background}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.lavender.card}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.lavender.button}}>

                                    </div>
                                    <div className="w-full h-1/4 rounded-bl-xl rounded-br-xl" style={{backgroundColor: themes.lavender.secondary}}>

                                    </div>
                                </div>
                            </div>
                        )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="warm">
                        {({ checked }) => (
                            <div>
                                <h1 className="font-semibold text-2xl">warm</h1>
                                <div className={checked ? activeStyle+style : style}>
                                    <div className="w-full h-1/4 rounded-tl-xl rounded-tr-xl" style={{backgroundColor: themes.warm.background}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.warm.card}}>

                                    </div>
                                    <div className="w-full h-1/4" style={{backgroundColor: themes.warm.button}}>

                                    </div>
                                    <div className="w-full h-1/4 rounded-bl-xl rounded-br-xl" style={{backgroundColor: themes.warm.secondary}}>

                                    </div>
                                </div>
                            </div>
                        )}
                    </RadioGroup.Option>
                </RadioGroup>
            </div>
                <button className='bg-black text-white mt-2 rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4' onClick={handleSubmit}>done</button>
                <Link className='border border-black text-black mt-2 rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4' to={`/${user.displayname}`}>cancel</Link>
        </div>
    )
}