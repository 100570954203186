import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";
import validator from "validator";
// firebase
import { auth, db } from "../Firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";


export const EditLink = () => {
    const navigate = useNavigate();
    const { index } = useParams();
    const [title, setTitle] = useState("");
    const [url, setURL] = useState("");
    const [username, setUsername] = useState("");
    const [cards, setCards] = useState([]);
    const [newTitle, setNewTitle] = useState("");
    const [newURL, setNewURL] = useState("");
    const [alertURL, setAlertURL] = useState(false);
    const [alertTitle, setAlertTitle] = useState(false);

    const handleSubmit = async () => {
        let submit = true;
        if (newTitle.length === 0) {
            setAlertTitle(true);
            submit = false;
        } else {
            setAlertTitle(false);
        }
        if (!validator.isURL(newURL)) {
            setAlertURL(true);
            submit = false;
        } else {
            setAlertURL(false);
        }
        if (submit) {
            cards[index].title = newTitle;
            cards[index].url = newURL;

            const userRef = doc(db, "users", auth.currentUser.uid);
            await updateDoc(userRef, { cards: cards }).then(docRef => {
                console.log("profile updated successfully to doc");
            }).catch(error => {
                console.log(error);
            });
            navigate("/edit");
        }
    }

    const getUser = async () => {
        // get user doc from auth user id
        const docReference = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docReference);
        const data = docSnap.data();
        let card = {}
        card = data.cards[index];
        if (card === undefined) {
            navigate('/'+data.displayname);
        }
        setTitle(card.title);
        setNewTitle(card.title);
        setURL(card.url);
        setNewURL(card.url);
    }

    const handleCancel = () => {
        navigate(-1);
    }

    useEffect(() => {
        // due to the fact that auth loading is not guarenteed before render
        //  need to let auth state change handle getting userName in hook
        const unsub = auth.onAuthStateChanged((authObj) => {
            unsub();
            if (authObj) {
                getUser();
            }
            else {
                // not logged in, reroute to home page
                navigate("/");
            }
        })
    }, []);

    return (
        <div className='h-screen space-y-4 flex flex-col items-center justify-center text-center bg-gray-100'>
            <h1 className='font-bold text-5xl'>edit link</h1>
            <div className="w-full flex flex-col items-center">
                <div className="bg-white drop-shadow-md rounded-md pt-6 pb-6 w-3/4 mt-2 mb-2">
                    {newTitle}
                </div>
                <h1 className='font-semibold text-2xl'>change title</h1>
                <p className={alertTitle ? "text-red-500 text-sm font-semibold" : "hidden"}>title cannot be empty</p>
                <input
                    type='text'
                    placeholder={title}
                    onChange={(event) => {
                        setNewTitle(event.target.value)
                    }}
                    className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-3/4"
                    maxLength={50}
                />
                <h1 className='font-semibold text-2xl'>change url</h1>
                <p className={alertURL ? "text-red-500 text-sm font-semibold" : "hidden"}>invalid url</p>
                <input
                    type='text'
                    placeholder={url}
                    onChange={(event) => {
                        if (event.target.value.slice(0, 7) === "http://" || event.target.value.slice(0, 8) === "https://") {
                            setNewURL(event.target.value);
                        }
                        else {
                            setNewURL("http://" + event.target.value);
                        }
                    }}
                    className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-3/4"
                />
                <button onClick={handleSubmit} className='bg-black mt-2 text-white rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4'>submit</button>
                <button onClick={handleCancel} className='border border-black mt-2 text-black rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4'>cancel</button>
            </div>
        </div>
    )
}