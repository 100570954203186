// react
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// firebase
import { auth, db } from '../Firebase';
import { updateEmail, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
// custom components
import { Menu } from '../components/Menu';
import { User } from './User';

export const Settings = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const navigate = useNavigate();
    const [updateEmailSuccess, setUpdateEmailSuccess] = useState(false);
    const [updateEmailFail, setUpdateEmailFail] = useState(false);
    const [updatePassSuccess, setUpdatePassSuccess] = useState(false);
    const [updatePassFail, setUpdatePassFail] = useState(false);

    const handleEmail = () => {
        // reauth user
        const user = auth.currentUser
        const credential = EmailAuthProvider.credential(
            user.email,
            pass
        )
        reauthenticateWithCredential(user, credential).then(() => {
            updateEmail(User, email).then(() => {
                setUpdateEmailSuccess(true);
            }).catch((error) => {
                console.log(error);
                setUpdateEmailFail(true);
            })
        }).catch((error) => {
            console.log(error);
            setUpdateEmailFail(true);
        })
    }

    const handlePassword = () => {
        // reauth user
        const user = auth.currentUser
        const credential = EmailAuthProvider.credential(
            user.email,
            oldPassword
        )
        reauthenticateWithCredential(user, credential).then(() => {
            updatePassword(user, newPassword).then(() => {
                setUpdatePassSuccess(true);
            }).catch((error) => {
                console.log(error);
                setUpdatePassFail(true);
            })
        }).catch((error) => {
            console.log(error);
            setUpdatePassFail(true);
        })
    }

    const getUser = async () => {
        // get user doc from auth user id
        const docReference = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docReference);
        const data = docSnap.data();
        setUsername(data.displayname);
    }

    useEffect(() => {
        // due to the fact that auth loading is not guarenteed before render
        //  need to let auth state change handle getting userName in hook
        const unsub = auth.onAuthStateChanged((authObj) => {
            unsub();
            if (authObj) {
                getUser();
                setIsAuth(true);
            } else {
                // not logged in, reroute to home page
                navigate("/");
            }
        });
    }, [])

    return (
        <div>
            <Menu isAuth={isAuth} username={username} color={"black"} />
            <div className='h-screen space-y-4 flex flex-col items-center text-center justify-center'>
                <h1 className='font-bold text-5xl'>settings</h1>
                <div className='space-y-2 w-full flex flex-col items-center text-center'>
                    <h1 className='font-semibold text-2xl'>change email</h1>
                    <p className={updateEmailSuccess ? "text-green-600 text-sm font-semibold" : "hidden"}>updated email successfully</p>
                    <p className={updateEmailFail ? "text-red-500 text-sm font-semibold" : "hidden"}>email already in use or password is incorrect</p>
                    <input
                        type='text'
                        placeholder='current password'
                        onChange={(event) => {
                            setPass(event.target.value);
                        }}
                        className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-3/4"
                    />
                    <input
                        type='text'
                        placeholder='new email'
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                        className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-3/4"
                    />
                    <button onClick={handleEmail} className='bg-black mt-2 text-white rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4'>submit</button>
                </div>
                <div className='space-y-2 w-full flex flex-col items-center text-center'>
                    <h1 className='font-semibold text-2xl'>change password</h1>
                    <p className={updatePassSuccess ? "text-green-600 text-sm font-semibold" : "hidden"}>updated password successfully</p>
                    <p className={updatePassFail ? "text-red-500 text-sm font-semibold" : "hidden"}>password incorrect</p>
                    <input
                        type='text'
                        placeholder='current password'
                        onChange={(event) => {
                            setOldPassword(event.target.value);
                        }}
                        className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-3/4"
                    />
                    <input
                        type='text'
                        placeholder='new password'
                        onChange={(event) => {
                            setNewPassword(event.target.value);
                        }}
                        className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-3/4"
                    />
                    <button onClick={handlePassword} className='bg-black mt-2 text-white rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4'>submit</button>
                    <Link to='/ghost' className='font-light underline mt-8'>delete account</Link>
                </div>
            </div>
        </div>
    );
}