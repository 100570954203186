// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAHos9Gj4Ej--xifdifr5oG9XSL-DfT-zc",
  authDomain: "hyprcrd.firebaseapp.com",
  projectId: "hyprcrd",
  storageBucket: "hyprcrd.appspot.com",
  messagingSenderId: "722675939699",
  appId: "1:722675939699:web:5857f289d5c77ff931ed06",
  measurementId: "G-BZ7ZT5W30C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// firebase auth
export const auth = getAuth(app);
// google auth provider
export const googleAuthProvider = new GoogleAuthProvider();
// db and storage
export const db = getFirestore(app);
export const storage = getStorage(app);
// analytics
const analytics = getAnalytics(app);