import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";
// firebase
import { auth, db } from "../Firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const DeleteLink = () => {
    const { index } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [username, setUsername] = useState("");
    const [cards, setCards] = useState([]);

    const getUser = async () => {
        // get user doc from auth user id
        const docReference = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docReference);
        const data = docSnap.data();
        setUsername(data.displayname);
        setCards(data.cards);
        let card = {}
        try {
            card = data.cards[index];
        } catch (error) {
            navigate(`/${username}`)
        }

        setTitle(card.title);
    }

    const handleSubmit = async () => {
        cards.splice(index, 1);
        const userRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userRef, { cards: cards }).then(docRef => {
            console.log("profile updated successfully to doc");
        }).catch(error => {
            console.log(error);
        });
        navigate("/edit");
    }

    const handleCancel = () => {
        navigate(-1);
    }
    
    useEffect(() => {
        // due to the fact that auth loading is not guarenteed before render
        //  need to let auth state change handle getting userName in hook
        const unsub = auth.onAuthStateChanged((authObj) => {
            unsub();
            if (authObj) {
                getUser();
            }
            else {
                // not logged in, reroute to home page
                navigate("/");
            }
        })
    }, []);

    return (
        <div className='h-screen space-y-4 flex flex-col items-center justify-center text-center bg-gray-100'>
            <h1 className='font-bold text-5xl'>delete link</h1>
            <div className="w-full flex flex-col items-center">
                <div className="bg-white drop-shadow-md rounded-md pt-6 pb-6 w-3/4 mt-2 mb-2">
                    {title}
                </div>
                <button onClick={handleSubmit} className='bg-black mt-2 text-white rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4'>yes</button>
                <button onClick={handleCancel} className='border border-black mt-2 text-black rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4'>cancel</button>
            </div>
        </div>
    );
}