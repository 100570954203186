import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// firebase
import { auth, db } from "../Firebase";
import { reauthenticateWithCredential, deleteUser, EmailAuthProvider } from "firebase/auth";
import { doc, deleteDoc } from "firebase/firestore";

export const Ghost = () => {
    const [password, setPassword] = useState("");
    const [alertPassword, setAlertPassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
            user.email,
            password
        )
        // reauth and delete user
        reauthenticateWithCredential(user, credential).then(() => {
            deleteUser(user);
            // delete doc from firestore
            deleteDoc(doc(db, "users", auth.currentUser.uid));
            navigate('/');
        }, (error) => {
            console.log(error);
            setAlertPassword(true);
        });
        
    }

    return (
        <div className="h-screen flex flex-col items-center text-center justify-center space-y-4">
            <h1 className="font-bold text-3xl">are you sure you want to delete your account? ://</h1>
            <p className={alertPassword ? "text-red-500 textd-sm font-semibold" : "hidden"}>can't go until you get your password correct :)</p>
            <input
                type='text'
                placeholder='current password'
                onChange={(event) => {
                    setPassword(event.target.value);
                }}
                className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-1/2"
            />
            <div className="space-x-16">
                <Link className="rounded-full text-xl underline" to='/settings'>cancel</Link>
                <button className="font-semibold text-xl text-white bg-black rounded-full pl-8 pr-8 pt-2 pb-2" onClick={handleSubmit}>yes</button>
            </div>
        </div>
    );
}