import { React, useState, useEffect } from "react";
// firebase imports
import { storage } from "../Firebase";
import { ref, getDownloadURL } from "firebase/storage";

export const UserHeader = (props) => {
  const { username, data } = props
  const [description, setDescription] = useState("")
  const [image, setImage] = useState("")
  const [theme, setTheme] = useState("");

  const getUser = async () => {
    const url = await getDownloadURL(ref(storage, `${data[0].profile_image}`))
    setImage(url);
    setDescription(data[0].description)
    setTheme(data[0].theme)
  }

  // render user image
  useEffect(() => {
    getUser();
  }, [data])

  return (
    <div className="flex flex-col items-center text-center space-y-2">
      <img src={image} className="w-48 h-48 rounded-full object-cover" />
      <h1 className="font-semibold text-2xl" style={{ color: theme.card }}>/{username}</h1>
      <h1 className="font-regular text-md" style={{ color: theme.card }}>{description}</h1>
    </div>
  );
}