import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// firebase
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../Firebase";
// custom components
import { Default } from "../components/Default";

export const AddLink = () => {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    const getUser = async () => {
        // get user doc from auth user id
        const docReference = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docReference);
        const data = docSnap.data();
        setUser(data);
    }

    useEffect(() => {
        // due to the fact that auth loading is not guarenteed before render
        //  need to let auth state change handle getting userName in hook
        const unsub = auth.onAuthStateChanged((authObj) => {
            unsub();
            if (authObj) {
                getUser();
            } else {
                // not logged in, reroute to home page
                navigate("/");
            }
        })
    }, [])
    return (
        <Default user={user} />
    )
}