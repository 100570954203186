import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Landing } from './pages/Landing';
import { Signup } from './pages/Signup';
import { Login } from './pages/Login';
import { User } from './pages/User';
import { QR } from './pages/QR';
import { Edit } from './pages/Edit';
import { AddLink } from './pages/AddLink';
import { Style } from './pages/Style';
import { Logout } from './pages/Logout';
import { Settings } from './pages/Settings';
import { Ghost } from './pages/Ghost';
import { EditLink } from './pages/EditLink';
import { DeleteLink } from './pages/DeleteLink';

import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className='font-outfit'>
        <Router>
          <Routes>
            <Route path='/' element={<Landing />}></Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/signup' element={<Signup />}></Route>
            <Route path='/:username' element={<User />}></Route>
            <Route path='/qr' element={<QR />}></Route>
            <Route path='/edit' element={<Edit />}></Route>
            <Route path='/add' element={<AddLink />}></Route>
            <Route path='/style' element={<Style />}></Route>
            <Route path='/logout' element={<Logout />}></Route>
            <Route path='/settings' element={<Settings />}></Route>
            <Route path='/ghost' element={<Ghost />}></Route>
            <Route path='/editlink/:index' element={<EditLink />}></Route>
            <Route path='/deletelink/:index' element={<DeleteLink />}></Route>
          </Routes>
        </Router>
        <Analytics />
    </div>
  );
}

export default App;
