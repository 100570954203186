import { useState } from "react";
import { useNavigate } from "react-router-dom";
// firebase
import { doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../Firebase";
// url validator
import validator from "validator";

export const Default = (props) => {
    const { user } = props
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [alertURL, setAlertURL] = useState(false);
    const [alertTitle, setAlertTitle] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        let submit = true;
        if (title.length === 0) {
            setAlertTitle(true);
            submit = false;
        } else if (title.length !== 0) {
            setAlertTitle(false);
        }
        if (!validator.isURL(url)) {
            setAlertURL(true);
            submit = false;
        } else if (validator.isURL(url)) {
            setAlertURL(false);
        }
        if (submit) {
            const cards = user.cards
            const card = {
                type: "default",
                title: title,
                url: url,
            }

            cards.push(card)

            const userRef = doc(db, "users", auth.currentUser.uid);
            await updateDoc(userRef, { cards: cards }).then(docRef => {
                console.log("profile updated successfully to doc");
            }).catch(error => {
                console.log(error);
            });
            navigate("/" + user.displayname);
        }
    }

    const handleCancel = () => {
        navigate(-1);
    }

    return (
        <div className="h-screen space-y-4 flex flex-col items-center justify-center text-center bg-gray-100">
            <h1 className="font-bold text-5xl">add a link</h1>
            <div className="w-full flex flex-col items-center">
                <div className="bg-white drop-shadow-md rounded-md pt-6 pb-6 w-3/4 mt-2 mb-2">
                    {title}
                </div>
                <h1 className='font-semibold text-2xl'>card title</h1>
                <p className={alertTitle ? "text-red-500 text-sm font-semibold" : "hidden"}>title cannot be empty</p>
                <input
                    type='text'
                    placeholder={title}
                    onChange={(event) => {
                        setTitle(event.target.value)
                    }}
                    className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-3/4"
                    maxLength={50}
                />
                <h1 className='font-semibold text-2xl'>url</h1>
                <p className={alertURL ? "text-red-500 text-sm font-semibold" : "hidden"}>invalid url</p>
                <input
                    type='text'
                    placeholder={url}
                    onChange={(event) => {
                        if (event.target.value.slice(0, 7) === "http://" || event.target.value.slice(0, 8) === "https://") {
                            setUrl(event.target.value);
                        }
                        else {
                            setUrl("http://" + event.target.value);
                        }
                    }}
                    className="rounded-xl border-black border-2 pl-2 pr-2 pt-0.5 pb-0.5 w-3/4"
                />
                <button onClick={handleSubmit} className='bg-black mt-2 text-white rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4'>submit</button>
                <button onClick={handleCancel} className='border border-black mt-2 text-black rounded-full pl-8 pr-8 pt-2 pb-2 w-3/4'>cancel</button>
            </div>
        </div>
    );
}
