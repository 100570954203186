import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// qr code generator
import QRCode from "react-qr-code";
// firebase 
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../Firebase";
// custom components
import { Menu } from "../components/Menu";

export const QR = () => {
    const [username, setUsername] = useState("");
    const [isAuth, setAuth] = useState(false)
    const navigate = useNavigate();

    const getUsername = async () => {
        const docReference = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docReference);
        const data = docSnap.data();
        setUsername(data.displayname);
    }

    useEffect(() => {
        // due to the fact that auth loading is not guarenteed before render
        //  need to let auth state change handle getting userName in hook
        const unsub = auth.onAuthStateChanged((authObj) => {
            unsub();
            if (authObj) {
                getUsername();
                setAuth(true);
            } else {
                // not logged in, reroute to home page
                navigate("/")
            }
        })
    })

    return (
        <div className="h-screen bg-gradient-to-br from-white via-white">
            <Menu isAuth={isAuth} username={username} color={"black"} />
            <div className="h-3/4 flex flex-col items-center justify-center">
                <div className="w-fit h-fit rounded-full bg-black p-16">
                    <QRCode value={`https://hyprcrd.com/${username}`} className="w-56 h-56" />
                </div>
                <h1 className="font-semibold text-3xl mt-4">/{username}</h1>
            </div>
        </div>
    );

}
